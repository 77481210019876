export const conversions = {
  XLSX_TO_XML: {
    name: "xlsx-to-xml",
    inputFile: {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      extension: ".xlsx",
    },
    outputFile: { type: "application/zip", extension: ".zip", encoded: false },
  },
  XML_TO_XLSX: {
    name: "xml-to-xlsx",
    inputFile: { type: "", extension: "*/*" },
    outputFile: {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      extension: ".xlsx",
      encoded: true,
    },
  },
};
