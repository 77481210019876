export const truncateFileName = (fileName, numOfChars) =>
  fileName.length > numOfChars
    ? fileName.slice(0, Math.ceil(numOfChars / 2)) +
      "..." +
      fileName.slice(-Math.floor(numOfChars / 2))
    : fileName;

export const isValidXml = (str) => {
  try {
    // Parse the string as XML
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(str, "application/xml");

    // Check for parsing errors
    const parserError = xmlDoc.getElementsByTagName("parsererror");
    if (parserError.length > 0) {
      console.error("XML Parsing Error:", parserError[0].textContent);
      return false;
    }

    return true;
  } catch (e) {
    console.error("Error during XML parsing:", e);
    return false;
  }
};

export const getFilenameFromContentDisposition = (contentDisposition) => {
  // Use a regular expression to extract the filename
  const match = contentDisposition.match(
    /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  );

  // If a match is found, clean up and return the filename
  if (match && match[1]) {
    return match[1].replace(/['"]/g, "");
  }

  // Return null or undefined if no filename is found
  return null;
};
