import React, { useRef, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import {
  getFilenameFromContentDisposition,
  isValidXml,
  truncateFileName,
} from "./utils/file";
import { conversions } from "./consts/conversions";
import { stringToBlob } from "./utils/blob";

import "./App.css";

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dragClasses, setDragClasses] = useState("drag-area");
  const [conversion, setConversion] = useState(conversions.XLSX_TO_XML);
  const [resultFile, setResultFile] = useState();
  const [error, setError] = useState();
  const [resultError, setResultError] = useState();
  const [resultLink, setResultLink] = useState("");

  const inputRef = useRef();

  const selectFile = (file) => {
    if (!file) return false;

    if (conversion.inputFile.type) {
      if (conversion.inputFile.type !== file.type) {
        setError(`Type of file you provided is invalid.`);
        setSelectedFile(undefined);
        setDragClasses(classNames("drag-area", "drag-error"));
        return;
      } else {
        setError();
        setSelectedFile(file);
        setDragClasses(classNames("drag-area"));
        return;
      }
    }

    const reader = new FileReader();

    // Define the file read event handler
    reader.onload = function (e) {
      const content = e.target.result; // File content as a string (for text files) or ArrayBuffer (for binary files)
      const isXml = isValidXml(content);

      if (isXml) {
        setError();
        setSelectedFile(file);
        setDragClasses(classNames("drag-area"));
        return;
      } else {
        setError(`Invalid file type. File should be in xml format.`);
        setSelectedFile(undefined);
        setDragClasses(classNames("drag-area", "drag-error"));
        return;
      }
    };
    reader.readAsText(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    selectFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragClasses(classNames("drag-area", "active"));
  };

  const handleDragLeave = () => {
    setDragClasses("drag-area");
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    selectFile(file);
  };

  const handleConversionChanged = (e) => {
    setConversion(
      e.target.checked ? conversions.XML_TO_XLSX : conversions.XLSX_TO_XML
    );
    setResultFile(undefined);
    setSelectedFile(undefined);
    setError(undefined);
    setDragClasses("drag-area");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append("excelFile", selectedFile);
      setResultFile(undefined);
      setResultLink("");
      setLoading(true);
      setResultError(undefined);

      await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/${conversion.name}`,
          formData
        )
        .then(async (res) => {
          if (conversion.name === "xlsx-to-xml") setResultLink(res.data);
          else {
            const contentDisposition = res.headers.get("content-disposition");

            const filename =
              getFilenameFromContentDisposition(contentDisposition);

            const blob = stringToBlob(
              res.data,
              512,
              conversion.outputFile.encoded
            );

            const url = window.URL.createObjectURL(blob);
            setResultLink(url);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            setResultError(
              `An error occured. ${error.response.data}` ||
                "Something went wrong. Please try again."
            );
          } else if (error instanceof Error) {
            setResultError(error.message);
          } else {
            setResultError("Something went wrong. Please try again.");
          }
        });

      setLoading(false);
    } else {
      alert("Select a file");
    }
  };

  const handleBrowseOnClick = async () => {
    inputRef.current.click();
    inputRef.current.value = "";
  };

  return (
    <div className="App">
      <h1>
        <span>Convert</span> File
      </h1>
      <div className="toggle">
        <span>.xlsx to .xml</span>
        <div className="toggle-input">
          <input type="checkbox" id="two" onChange={handleConversionChanged} />
          <label className="label" htmlFor="two">
            <div className="handle"></div>
          </label>
        </div>
        <span>.xml to .xlsx</span>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          className={dragClasses}
          id="dropcontainer"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleFileDrop}
        >
          <div className="icon">
            <i className="fas fa-cloud-upload-alt"></i>
          </div>
          <header>Drag & Drop to Upload File</header>
          <header>OR &nbsp;</header>
          <button type="button" onClick={handleBrowseOnClick}>
            Browse File
          </button>
          <input
            accept={conversion.inputFile.type}
            ref={inputRef}
            type="file"
            hidden
            onChange={handleFileChange}
          />
          {selectedFile && (
            <div className="selected-file">
              <i className="fas fa-thin fa-paperclip"></i>
              {truncateFileName(selectedFile?.name, 30)}
            </div>
          )}
          {error && <div className="error">{error}</div>}
        </div>

        <button type="submit" disabled={!selectedFile || loading}>
          {loading ? (
            <i className="fas fa-solid fa-spinner loader"></i>
          ) : (
            "Convert"
          )}
        </button>
        {resultLink && (
          <div className="result-file">
            <span>Result file</span>
            <a href={resultLink} download={resultLink}>
              <i className="fas fa-solid fa-download"></i>
            </a>
          </div>
        )}
        {resultError && <div className="result-error">{resultError}</div>}
      </form>
    </div>
  );
}

export default App;
